define(['app', '$console'], (app, $console) => {

  const dynamicProgressBar = () => {
    const component = {};

    const _config = {
      selectors: {
        progressElement: '.dynamicProgressBar_progress'
      }
    };

    let observer;

    const _init = element => {
      component.element = element;
      if (!component.element || component.element instanceof HTMLElement === false ) {
        $console.error('dynamicProgressBar.init was called without the expected HTMLElement');
        return;
      }
      component.progressElement = component.element.querySelector(component.config.selectors.progressElement);
      if (!component.progressElement) {
        $console.error(`Could not locate element matching selector ${component.config.selectors.progressElement}`);
        return;
      }
      _setInternalVars();
      component.updateProgress();
      observer = new MutationObserver(mutations =>
        mutations.map(() => {
          _setInternalVars();
          _updateProgress();
        }));
      observer.observe(component.progressElement, {
        attributes: true,
        attributeFilter: ['data-value', 'data-max']
      });
      return component;
    };

    const _getRemainder = () => {
      if (component.max - component.value <= 0) {
        return 0;
      } else if (component.value <= 0) {
        return component.max;
      }
      return component.max - component.value;
    };

    const _setInternalVars = () => {
      component.value = component.progressElement.dataset.value ? parseFloat(component.progressElement.dataset.value) : 0;
      component.max = component.progressElement.dataset.max ? parseFloat(component.progressElement.dataset.max) : 100;
    };

    const _updateProgress = () => {
      if (component.value === 0 || component.max === 0) {
        component.progressElement.style.width = '0%';
        return component.progressElement.style.width;
      } else if (component.value >= component.max) {
        component.progressElement.style.width = '100%';
        return component.progressElement.style.width;
      }
      // Floor instead of round so we never hit 100% when max isn't reached
      component.progressElement.style.width = Math.floor((component.value / component.max) * 100) + '%';
      return component.progressElement.style.width;
    };

    component.config = _config;
    component.init = _init;
    component.getRemainder = _getRemainder;
    component.updateProgress = _updateProgress;

    return component;
  };

  return dynamicProgressBar;
});

